import { TokenParams, TokenResponse } from '@okta/okta-auth-js';

import { getReAuthenticationParams } from './get-re-authentication-params';
import { oktaAuth } from '../../root.component';

export enum ReAuthEntityTypeEnum {
    INVOICE = 'INVOICE',
    BENEFICIARY = 'BENEFICIARY',
}

type ReAuthenticateParams = {
    entityType: ReAuthEntityTypeEnum;
    entityId: string;
    options?: TokenParams;
    requiredItems?: string[];
    enabled?: boolean;
    callback: (response: TokenResponse) => void;
    onError?: () => void;
};

export const reAuthenticate = async ({
    entityType,
    entityId,
    options = {},
    requiredItems = ['mfa', 'otp', 'pwd'],
    enabled = true,
    callback,
    onError = () => {},
}: ReAuthenticateParams): Promise<void> => {
    try {
        if (!enabled) {
            if (callback) {
                callback(null);
            }

            return;
        }

        const { authState, authNonce } = await getReAuthenticationParams(
            entityType,
            entityId
        );

        if (!authState || !authNonce) {
            onError();

            return;
        }

        const res = await oktaAuth.token.getWithPopup({
            acrValues: 'urn:okta:loa:2fa:any',
            maxAge: 0,
            state: authState,
            nonce: authNonce,
            ...options,
        });

        const amr = res?.tokens?.idToken?.claims?.amr;

        if (
            Array.isArray(amr) &&
            requiredItems.every(item => amr.includes(item))
        ) {
            if (callback) {
                callback(res);
            }
        } else {
            onError();
        }
    } catch (error) {
        console.error('Re-authentication failed:', error);

        onError();
    }
};
