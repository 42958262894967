import axios from 'axios';

import { ReAuthEntityTypeEnum } from '.';
import { handleTokenExpiration } from '../handle-expired-token';

export const getReAuthenticationParams = async (
    entityType: ReAuthEntityTypeEnum,
    entityId: string
) => {
    const accessToken = await handleTokenExpiration();

    try {
        const response = await axios.get(
            `${window.env.API_BASE_URL}contractor-payments/sca/reauthentication/${entityType}/${entityId}/init`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken.accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching reauthentication:', error);
        throw error;
    }
};
