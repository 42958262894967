import { AccessToken } from '@okta/okta-auth-js';

import { oktaAuth } from './okta-instance';

export const handleTokenExpiration = async (): Promise<AccessToken> => {
    const { tokenManager } = oktaAuth;

    const accessToken = await tokenManager.get('accessToken');

    if (accessToken !== undefined && tokenManager.hasExpired(accessToken)) {
        const isDev = process.env.NODE_ENV === 'development';

        if (isDev) {
            console.log('Access token expired');
        }

        return tokenManager.renew('accessToken').then(
            result => {
                if (isDev) console.log('Token renewed: ', { result });

                return result;
            },
            error => {
                if (isDev)
                    console.error(
                        `Error happen during the token renew.${JSON.stringify(
                            error
                        )}`
                    );
                oktaAuth.signOut();

                return error;
            }
        );
    }

    return accessToken as AccessToken;
};
