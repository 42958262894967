import { useMemo } from 'react';

import { useOktaAuth } from '@okta/okta-react';

import {
    isAdmin,
    isEmployee,
    isExternalLineManger,
    isHrManager,
    isLineManager,
    isCompanyAdmin,
    isContractor,
} from './helpers';
import { UserGroup, UserIdentityDetails, WMUserClaims } from './types';

export const useUserIdentity = () => {
    const { authState } = useOktaAuth();

    const userClaim = authState?.accessToken?.claims as unknown as WMUserClaims;

    const userGroups = useMemo(() => {
        if (authState?.isAuthenticated) {
            return userClaim?.groups as UserGroup[];
        }

        return [];
    }, [authState, userClaim]);

    const userIdentityInfo: UserIdentityDetails = useMemo(
        () => ({
            isAuthenticated: authState?.isAuthenticated,
            isAdmin: isAdmin(userGroups),
            isHrManager: isHrManager(userGroups),
            isEmployee: isEmployee(userGroups),
            isLineManager: isLineManager(userGroups),
            isCompanyAdmin: isCompanyAdmin(userGroups),
            isExternalLineManager: isExternalLineManger(userGroups),
            isContractor: isContractor(userGroups),
            companyId: authState?.accessToken?.claims?.companyId as string,
            userInfo: userClaim,
        }),
        [authState, userGroups, userClaim]
    );

    return {
        userIdentityInfo,
    };
};
