import { OktaAuth } from '@okta/okta-auth-js';

const ISSUER = window.env.OKTA_ISSUER;
const CLIENT_ID = window.env.OKTA_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

function initializeOktaInstance() {
    return new OktaAuth({
        issuer: ISSUER,
        clientId: CLIENT_ID,
        redirectUri: REDIRECT_URI,
        postLogoutRedirectUri: `${window.location.origin}/login`,
        tokenManager: { autoRenew: true, expireEarlySeconds: 30 },
        scopes: ['email', 'openid', 'offline_access', 'profile'],
    });
}

export const oktaAuth =
    window.oktaInstance === undefined
        ? initializeOktaInstance()
        : window.oktaInstance;
window.oktaInstance = oktaAuth;
