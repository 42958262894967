import { UserGroup } from './types';

const isHrManager = (groups: UserGroup[]): boolean => {
    if (!groups) return false;

    return groups?.includes('CompanyHrManagers');
};

const isEmployee = (groups: UserGroup[]): boolean => {
    if (!groups) return false;

    return groups?.includes('Employees') || groups.includes('Candidates');
};

const isContractor = (groups: UserGroup[]): boolean => {
    if (!groups) return false;

    return groups?.includes('Contractor');
};

const isAdmin = (groups: UserGroup[]): boolean => {
    if (!groups) return false;

    return (
        groups?.includes('Administrators') ||
        groups?.includes('SuperAdmins') ||
        groups?.includes('Operators')
    );
};

const isLineManager = (groups: UserGroup[]): boolean => {
    if (!groups) return false;

    return groups?.includes('LineManagers');
};

const isExternalLineManger = (groups: UserGroup[]): boolean => {
    if (!groups) return false;

    return groups?.includes('Everyone') && groups.includes('LineManagers');
};

const isCompanyAdmin = (groups: UserGroup[]): boolean => {
    if (!groups) return false;

    return groups?.includes('CompanyAdmin');
};

export {
    isAdmin,
    isHrManager,
    isEmployee,
    isLineManager,
    isExternalLineManger,
    isCompanyAdmin,
    isContractor,
};
