import React, { useCallback, useEffect } from 'react';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { addBreadcrumb } from '@sentry/react';

const ISSUER = window.env.OKTA_ISSUER;
const CLIENT_ID = window.env.OKTA_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
declare global {
    interface Window {
        oktaInstance: OktaAuth;
    }
}

function initializeOktaInstance() {
    return new OktaAuth({
        issuer: ISSUER,
        clientId: CLIENT_ID,
        redirectUri: REDIRECT_URI,
        postLogoutRedirectUri: `${window.location.origin}/login`,
        tokenManager: { autoRenew: true, expireEarlySeconds: 30 },
        scopes: ['email', 'openid', 'offline_access', 'profile'],
    });
}

export const oktaAuth = initializeOktaInstance();

export const AuthenticationWrapper: React.FC<{ children }> = ({ children }) => {
    const restoreOriginalUri = useCallback(
        (_oktaAuth: OktaAuth, originalUri: string) => {
            const redirectTo = originalUri || '/';

            window.location.href = toRelativeUrl(
                redirectTo,
                window.location.origin
            );
        },
        []
    );

    const oktaLifecycleLog = useCallback(
        eventName => (tokenName: string) => {
            addBreadcrumb({
                message: `Okta event ${eventName} happen!`,
                data: { tokenName },
                level: 'log',
            });
        },
        []
    );

    useEffect(() => {
        oktaAuth?.tokenManager?.on('error', error => {
            addBreadcrumb({
                level: 'error',
                message: 'Okta error!',
                data: { error },
            });
        });

        oktaAuth.tokenManager.on('renewed', oktaLifecycleLog('renewed'));
        oktaAuth.tokenManager.on('added', oktaLifecycleLog('added'));
        oktaAuth.tokenManager.on('expired', oktaLifecycleLog('expired'));
        oktaAuth.tokenManager.on('removed', oktaLifecycleLog('removed'));

        return () => {
            addBreadcrumb({ message: 'Stopping okta!', level: 'log' });
        };
    }, [oktaLifecycleLog]);

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    );
};
